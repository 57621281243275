var data = require("../copy/traduction.json");

const button = document.querySelector(".button");
var buttunValue = button.textContent;

function LanguageNavigator() {
  let LanguageNavigator = navigator.language.substr(0, 2);
  LanguageNavigator = LanguageNavigator.toLocaleLowerCase();
  return LanguageNavigator;
}
LanguageNavigator();

function LangueButton() {
  if (buttunValue == "fr") {
    button.textContent = "en";
  }
  if (buttunValue == "en") {
    button.textContent = "fr";
  }
}

function defaultLanguage() {
  let defaultLanguage = LanguageNavigator();

  if (defaultLanguage == "en") {
    buttunValue = "en";
    dataString();
    button.textContent = "fr";
  }

  if (defaultLanguage == "fr") {
    buttunValue = "fr";
    dataString();
    button.textContent = "en";
  } else {
    buttunValue = "en";
    dataString();
    button.textContent = "fr";
  }
}
defaultLanguage();

button.addEventListener("click", (e) => {
  buttunValue = button.textContent;
  LangueButton();
  dataString();
});

function dataString() {
  const dataString = document.querySelectorAll("[data-key]");
  dataString.forEach((element) => {
    let dataKey = element.getAttribute("data-key");

    element.innerHTML = data.languages[buttunValue].strings[dataKey];
  });
}
