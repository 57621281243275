/* eslint-disable */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";

gsap.registerPlugin(ScrollTrigger);

let start;
if (window.matchMedia("(max-width: 550px)").matches) {
  start = "top 60%";
} else {
  start = "top 70%";
}

const paramètres = {
  duration: 1.7,
  ease: "power1",
  markers: false,
  start: start,
  end: "+=200",
};

var nbrTime = true;
function animateFrom(elem, direction) {
  if (elem.classList.contains("disable")) {
    null;
  } else {
    direction = direction | 1;

    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal")) {
      elem.classList.add("disable");
    }
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = 0;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 0;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromtop")) {
      x = 0;
      y = 0;
    }

    gsap.fromTo(
      elem,
      { x: x, y: y, opacity: 0 },
      {
        duration: paramètres.duration,
        x: 0,
        y: 0,
        opacity: 1,
        ease: paramètres.ease,
        overwrite: "auto",
      }
    );
  }
}

function hide(elem) {
  gsap.set(elem, { opacity: 0 });
}

// Get all elements with class big
let animatedParagraphs = document.querySelectorAll('.big.animate');

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.addEventListener('scrollStart', onScroll);
  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    hide(elem);

    ScrollTrigger.create({
      trigger: elem,
      markers: paramètres.markers,
      start: paramètres.start,
      end: paramètres.end,
  
      onEnter: () => {
        animateFrom(elem);
      },
      onComplete: () => {},
    });
  });

  const bigParagraphs = document.querySelectorAll('.big');
  if(bigParagraphs.length > 0) {
    bigParagraphs.forEach(p => {
      const distance = p.getBoundingClientRect().top - window.innerHeight;
      p.style.marginLeft = `${distance < 0 ? 0 : distance}px`;
      ScrollTrigger.create({
        trigger: p,
        start: 'bottom bottom',
        onEnter: () => {
          p.classList.add('animate');
          animatedParagraphs = document.querySelectorAll('.big.animate');
        }
      })
    })
  }
});

const bodyScrollBar = Scrollbar.init(document.querySelector('.smoothScroll'), {
  damping: 0.1,
  delegateTo: document,
});

bodyScrollBar.setPosition(0, 0);
bodyScrollBar.track.xAxis.element.remove();

// Change .big elements margin on scroll
function onScroll(pos) {
  if(animatedParagraphs.length > 0) {
    animatedParagraphs.forEach(p => {
      if(p) {
        p.style.left = - pos + 'px';
      }
    })
  }
}
// Listen for scroll event
bodyScrollBar.addListener((status) => {
  onScroll(status.offset.y);
})

ScrollTrigger.scrollerProxy("body", {
  scrollTop(value) {
    if (arguments.length) {
      bodyScrollBar.scrollTop = value;
    }
    return bodyScrollBar.scrollTop;
  },
});

// Project meta image boxes
const projectMetaBoxes = document.querySelectorAll('.project-meta__imgs');
if(projectMetaBoxes.length > 0) {
  projectMetaBoxes.forEach(box => {
    box.addEventListener('mouseenter', (e) => {
      box.classList.add('animate-boxes');
    })
  })
}
