import axios from 'axios'

// Contact form
const contactForm = document.getElementById('contact-form');
const responseMessageWrapper = document.getElementById('response-message-wrapper');
const responseMessage = document.getElementById('response-message');
const submitBtn = document.getElementById('submit-btn');

if(contactForm) {
  contactForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    toggleFormMessage('hide', '');

    const name = contactForm.querySelector('#name');
    const email = contactForm.querySelector('#email');
    const message = contactForm.querySelector('#message');

    // if(name.value.trim() === '' || email.value.trim() === '' || subject.value.trim() === '' || message.value.trim() === '') {
    //   return alert('Fill in all required fields!');
    // }

    // submitBtn.innerHTML = '<div class="loader"></div>';
    submitBtn.setAttribute('disabled', true);

    const formData = new FormData();
    formData.append('name', name.value.trim());
    formData.append('email', email.value.trim());
    formData.append('message', message.value.trim());
    
    try {
      const res = await axios.post(contactForm.getAttribute('action'), formData);
      toggleFormMessage('show', res.data.message);
      // submitBtn.innerHTML = 'Send';
      submitBtn.removeAttribute('disabled');
      if(res.data.success) {
        name.value = '';
        email.value = '';
        message.value = '';
        contactForm.style.display = 'none';
      }
    } catch (err) {
      console.log(err)
      toggleFormMessage('show', 'Something went wrong. Please try again!');
    }
  });
}

const toggleFormMessage = (mode, message) => {
  if(!message) return 

  if(mode === 'show') {
    responseMessageWrapper.style.display = 'block';
    responseMessage.innerText = message;
  }else if(mode === 'hide') {
    responseMessageWrapper.style.display = 'none';
    responseMessage.innerText = '';
  }
}